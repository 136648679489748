<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Kode Booking<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.aranjo_kode_booking" placeholder="e.g RG00000001"/>
                  <VValidate 
                    name="Kode Booking" 
                    v-model="row.aranjo_kode_booking" 
                    :rules="toValidate(mrValidation.aranjo_kode_booking)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Jenis Tindakan<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Jenis Tindakan" type="text" class="form-control" v-model="row.aranjo_jenis_tindakan"/>
                  <VValidate 
                    name="Jenis Tindakan" 
                    v-model="row.aranjo_jenis_tindakan" 
                    :rules="toValidate(mrValidation.aranjo_jenis_tindakan)"
                  />
                </b-form-group>
              </b-col>

              
              <b-col lg="6">
                <b-form-group>
                  <label>Nama Poli<span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Nama Poli" v-model="row.aranjo_mpo_id"
                  :options="$parent.mPoli" label="text" :clearable="true"
                  :reduce="v=>v.value"></v-select>

                  <VValidate 
                    name="Nama Poli" 
                    v-model="row.aranjo_mpo_id" 
                    :rules="toValidate(mrValidation.aranjo_mpo_id)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>No Peserta</label>
                  <b-form-input :formatter="$parent.normalText" placeholder="No Peserta" type="text" class="form-control" v-model="row.aranjo_no_peserta"/>
                    <VValidate 
                      name="No Peserta" 
                      v-model="row.aranjo_no_peserta" 
                      :rules="toValidate(mrValidation.aranjo_no_peserta)"
                    />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label for="stepNotes">Tanggal Operasi <span class="text-danger mr5">*</span></label>
                  <div class="input-group">
                    <div class="input-group mb-3">
                      <datepicker
                        input-class="form-control transparent"
                        placeholder="Tanggal Operasi" class="my-datepicker"
                        calendar-class="my-datepicker_calendar"
                        v-model="row.aranjo_tanggal_operasi"
                      >
                      </datepicker>
                      <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i
                            class="icon-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                  <VValidate :name="`Tanggal Operasi`" 
                  v-model="row.aranjo_tanggal_operasi"
                  :rules="{required: 1}" />
                </b-form-group>
              </b-col>

                                              
              
              
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.Terlaksana"
                    v-model="row.aranjo_terlaksana"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>



<script>
import $ from 'jquery'
const _ = global._
import Datepicker from 'vuejs-datepicker'

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  components: {
    Datepicker
  },
  methods: {
    toValidate(val){
      return {...val}
    }
  },
}
</script>